<!--
@license GNU AGPL version 3 or any later version

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as
published by the Free Software Foundation, either version 3 of the
License, or (at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <http://www.gnu.org/licenses/>.
-->
<template>
  <!-- Custom Menu [Colaboras] -->
  <div id="side-menu-colaboras">
  <!-- ** -->
    <div id="side-menu">
      <div class="side-menu-header" v-if="settings || !openerHover || (!avatar && !alwaysDisplayed && logo) || avatar">
        <SettingsButton
          v-if="settings"
          v-bind:href="settings.href"
          v-bind:label="settings.name"
          v-bind:avatar="settings.avatar" />
        <AppSearch v-model:search="search" />
        <OpenerButton />
        <!--
        <Logo
          v-if="!avatar && !alwaysDisplayed && logo" v-bind:classes="{'side-menu-logo': true, 'avatardiv': false}"
          v-bind:image="logo"
          v-bind:link="logoLink"
        />
        <Logo
          v-if="avatar" v-bind:classes="{'side-menu-logo': true, 'avatardiv': true}"
          v-bind:image="avatar"
          v-bind:link="logoLink"
        />
        -->
      </div>

      <div class="side-menu-apps-list-wrapper">
        <ul class="side-menu-apps-list side-menu-apps-list-colaboras" :class="{'side-menu-apps-list--with-settings': !!settings}">
          <SideMenuApp
            v-for="(app, key) in apps"
            v-if="!hiddenApps.includes(app.id) && searchMatch(app.name)"
            v-bind:classes="{'side-menu-app': true, 'active': app.active}"
            v-bind:key="key"
            v-bind:icon="app.icon"
            v-bind:label="app.name"
            v-bind:href="app.href"
            v-bind:target="targetBlankApps.indexOf(app.id) !== -1 ? '_blank' : undefined"
          />
        </ul>
        <!-- Custom Menu [Colaboras] -->
        <SideMenuColaborasFooter 
          v-bind:classes="{'side-menu-apps-list': true, 'side-menu-app-colaboras': true, 'side-menu-app': true, 'active': false}"
          v-bind:logoLink="logoLink"
          v-bind:isColaborasFooterLoading="isColaborasFooterLoading"
          v-bind:enableColaborasSettings="enableColaborasSettings"
        />
        <!-- ** -->
      </div>
    </div>
    <SideMenuColaborasFlotatingMenu
      v-bind:isColaborasFooterLoading="false"
    />
    <!--
    <div id="colaboras-floating-menu" class="colaboras-floating-menu" 
        style="background-color: red;position:fixed;bottom:0;right:0;width:250px;">
      Floating Menu 
      <br>
      sfsdfsf
    </div>
    -->
  <!-- Custom Menu [Colaboras] -->
  </div>
  <!-- ** -->
</template>

<script>
import axios from 'axios'
import OpenerButton from './OpenerButton'
import SettingsButton from './SettingsButton'
import SideMenuApp from './SideMenuApp'
import AppSearch from './AppSearch'
import Logo from './Logo'
import { loadState } from '@nextcloud/initial-state'
/** Custom Menu [Colaboras] */
import SideMenuColaborasFooter from './SideMenuColaborasFooter'
import SideMenuColaborasFlotatingMenu from './SideMenuColaborasFlotatingMenu.vue'
/* 
  //Incluir 'parche' en webpack.js:

  const webpack = require('webpack');
  ...
  
  plugins: [
  ...
  new webpack.ProvidePlugin({
      process: 'process/browser',
      Buffer: ['buffer', 'Buffer']
  }),
*/
/** */

export default {
  name: 'SideMenu',
  components: {
    SettingsButton,
    OpenerButton,
    SideMenuApp,
    Logo,
    AppSearch,
    /** Custom Menu [Colaboras] */
    SideMenuColaborasFooter,
    SideMenuColaborasFlotatingMenu,
    /** */
  },
  data() {
    return {
      apps: [],
      logo: null,
      logoLink: null,
      avatar: null,
      forceLightIcon: false,
      targetBlankApps: [],
      hiddenApps: [],
      settings: null,
      openerHover: false,
      alwaysDisplayed: false,
      search: '',
      /** Custom Menu [Colaboras] */
      isColaborasFooterLoading: true,
      enableColaborasSettings: false,
      /** */
    }
  },
  methods: {
    retrieveApps() {
      const ncApps = loadState('core', 'apps', {})
      let orders = {}
      let finalApps = []

      window.menuAppsOrder.forEach((app, order) => {
        orders[app] = order + 1
      })

      /** Custom Menu [Colaboras] */
      this.isColaborasFooterLoading = false;
      //if (window.ColaborasData.IsAdmin === true) {
      /*
      if (window.ColaborasData.IsAdmin === true) {
        this.enableColaborasSettings = true;
      }
      */
      /** */

      for (let id in ncApps) {

        /** Custom Menu [Colaboras] */
        if (id === "mail") {
          //Si el usuario no tiene contratado correo, no añadimos el elemento 'mail' al menu.
          if (window.ColaborasData.MailAppEnabled !== true) {
            continue;
          }
        }
        /** */

        if (window.topMenuApps.includes(id) && !window.topSideMenuApps.includes(id)) {
          continue
        }

        if (this.hiddenApps.includes(id)) {
          continue
        }

        let app = ncApps[id]
        app.order = orders[id] || null

        finalApps.push(app)
      }

      finalApps.sort((a, b) => {
        if (a.order === null || b.order === null) {
          return a.name < b.name ? -1 : 1
        }

        return a.order < b.order ? -1 : 1
      })

      this.apps = finalApps

      document.querySelector('body').dispatchEvent(new CustomEvent('side-menu.apps', {
        detail: {apps: this.apps},
      }))
    },

    retrieveConfig() {
    },

    hasSearchMatch(apps) {
      if (this.search.trim() === '') {
        return true
      }

      for (let key in apps) {
        if (this.searchMatch(apps[key].name)) {
          return true
        }
      }

      return false
    },

    searchMatch(name) {
      if (this.search.trim() === '') {
        return true
      }

      return name.toLowerCase().includes(this.search.toLowerCase())
    },
  },
  mounted() {
    axios
      .get(OC.generateUrl('/apps/side_menu/js/config'))
      .then((response) => {
        const config = response.data

        this.targetBlankApps = config['target-blank-apps']
        this.forceLightIcon = config['force-light-icon']
        this.avatar = config['avatar']
        this.logo = config['logo']
        this.logoLink = config['logo-link']
        this.settings = config['settings']
        this.openerHover = config['opener-hover']
        this.alwaysDisplayed = config['always-displayed']
        this.hiddenApps = config['big-menu-hidden-apps']
        this.retrieveApps()
      })
  }
}
</script>
