<template>
    <NcModal size="{{ toastSize }}" @close="closeToast">
        <div class="support-form-modal">
            <div class="support-form-modal__header">
                {{ toastTitle }}
			</div>
            <div class="support-form-modal__custom-input">
                <p class="support-form-modal__custom-input__custom-hint">
                    {{ toastMessage }}
                </p>
            </div>
        </div>
    </NcModal>
</template>

<script>
import NcButton from '@nextcloud/vue/dist/Components/NcButton.js'
import NcModal from '@nextcloud/vue/dist/Components/NcModal.js'

export default {
	name: 'SideMenuColaborasToast',
    components: {
        NcButton,
		NcModal,
	},
    data() {
		return {}
	},
    props: {
        toastSize: {
            type: String,
            required: true,
            default: "small",
        },
        toastTitle: {
            type: String,
            required: true
        },
        toastMessage: {
            type: String,
            required: true
        },
    },
    methods: {
        closeToast() {
			this.$emit('close');
		},
    }
}
</script>

<style lang="scss" scoped>
.support-form-modal {
	padding: 10px 25px 20px 25px;

    &__header {
		text-align: center;
		font-weight: bold;
		margin: 10px 0;
	}

    &__custom-input {
		display: flex;
		width: 100%;

        &__custom-textarea {
            width: 100%;
        }

        &__custom-hint {
            margin: 0 10px 5px 15px;
            line-height: 120%;
        }

        &__custom-status {
            margin: 0 0 5px 15px;
        }
	}

    .status-buttons {
		display: flex;
		padding: 3px;
		padding-left:0;
		gap: 3px;
	}
}
</style>
