<template>
    <NcModal size="large" @close="closeModal">
        <div class="support-form-modal">
            <div class="support-form-modal__header">
                <h2>{{ t('side_menu', 'Send bug report') }}</h2>
			</div>
            <div class="support-form-modal__custom-input">
                <p class="support-form-modal__custom-input__custom-hint">
                    {{ t('side_menu', "If an error has happened and you want to let us know about it, fill in the following text field with a description of the problem you have encountered.")}}
                    
                </p>
            </div>
            <div class="support-form-modal__custom-input">
                <textarea rows="5"
                    class="support-form-modal__custom-input__custom-textarea"
                    v-bind:class="messageContentClass"
                    v-model="messageContent">
                </textarea>
            </div>
            <div class="support-form-modal__custom-input">
                <p class="support-form-modal__custom-input__custom-status"
                    v-bind:class="statusTextClass">
                    {{ statusText }}
                </p>
            </div>
            <div class="status-buttons">
                <NcButton 
                    @click="submitForm"
                    :disabled="isProcessingForm">
					{{ t('side_menu', 'Send') }}
				</NcButton>
            </div>
        </div>
    </NcModal>
</template>

<script>
import axios from '@nextcloud/axios'
import NcButton from '@nextcloud/vue/dist/Components/NcButton.js'
import NcModal from '@nextcloud/vue/dist/Components/NcModal.js'


export default {
	name: 'SideMenuColaborasSupportForm',
    components: {
        NcButton,
		NcModal,
	},
    data() {
		return {
            messageContent: '',
            messageContentClass: '',
            statusTextClass: '',
            isFormError: false,
            isFormSuccess: false,
            statusText: '',
            isProcessingForm: false,
		}
	},
    methods: {
        closeModal() {
			this.$emit('close')
		},
        resetForm() {
            this.isFormError = false;
            this.isFormSuccess = false;
            this.statusText = "";
            this.statusTextClass = "";
            this.messageContentClass = "";
        },
        showFormSuccess(successMessage) {
            if (successMessage) {
                this.isFormSuccess = true;
                this.isFormError = false;
                this.messageContentClass = "input-success";
                this.statusTextClass = "text-success";
                this.statusText = successMessage;
            }
        },
        showFormError(errorMessage) {
            if (errorMessage) {
                this.isFormError = true;
                this.isFormSuccess = false;
                this.messageContentClass = "input-error";
                this.statusTextClass = "text-error";
                this.statusText = errorMessage;
            }
        },
        submitForm() {
            this.resetForm();
            this.isProcessingForm = true;
            
            // Do not submit if message is empty
			if (this.messageContent.trim() === '') {
                this.showFormError(t('side_menu', "Message body can't be empty"));
                this.isProcessingForm = false;
				return;
			}

            this.processForm();
        },
        async processForm() {
            try {
                this.resetForm();
                this.isProcessingForm = true;
                this.statusText = t('side_menu', "Sending message...");

                axios
                .post(OC.generateUrl('/apps/side_menu/colaboras/internalmessage'), {
                    type: "notifyerror",
                    message: this.messageContent,
                    requestUrl: window.location.href,
                })
                .then((response) => {
                    var responseData = response.data;
                    if (responseData.status && (responseData.status === "success")) {
                        this.showFormSuccess(t('side_menu', "Message sent successfully"));
                    } else {
                        this.showFormError(t('side_menu', "Error: Message couldn't be sent"));
                    }
                    this.isProcessingForm = false;
                })
                .catch((error) => {
                    this.showFormError(t('side_menu', "Error: Message couldn't be sent"));
                    this.isProcessingForm = false;
                })
            } catch (error) {
                this.showFormError(t('side_menu', "Error: Message couldn't be sent"));
                this.isProcessingForm = false;
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.support-form-modal {
	padding: 10px 25px 20px 25px;

    &__header {
		text-align: center;
		font-weight: bold;
		margin: 10px 0;
	}

    &__custom-input {
		display: flex;
		width: 100%;

        &__custom-textarea {
            width: 100%;
        }

        &__custom-hint {
            margin: 0 10px 5px 15px;
            line-height: 120%;
        }

        &__custom-status {
            margin: 0 0 5px 15px;
        }
	}
    
    .input-success {
        border-color: var(--color-success) !important;
    }

    .input-error {
        border-color: var(--color-error) !important;
    }

    .text-success {
        color: var(--color-success) !important;
    }

    .text-error {
        color: var(--color-error) !important;
    }

    .status-buttons {
		display: flex;
		padding: 3px;
		padding-left:0;
		gap: 3px;
	}
}
</style>