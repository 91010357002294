import { render, staticRenderFns } from "./SideMenuColaborasSupportForm.vue?vue&type=template&id=9c78d58a&scoped=true"
import script from "./SideMenuColaborasSupportForm.vue?vue&type=script&lang=js"
export * from "./SideMenuColaborasSupportForm.vue?vue&type=script&lang=js"
import style0 from "./SideMenuColaborasSupportForm.vue?vue&type=style&index=0&id=9c78d58a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c78d58a",
  null
  
)

export default component.exports