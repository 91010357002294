<!--
@license GNU AGPL version 3 or any later version

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as
published by the Free Software Foundation, either version 3 of the
License, or (at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <http://www.gnu.org/licenses/>.
-->
<template>
  <ul class="side-menu-apps-list side-menu-apps-list-colaboras-footer" v-if="!isColaborasFooterLoading">

    <!-- Menú personalización ajustes Colaboras - Doble autentificación -->
    <!--
    <li class="side-menu-app side-menu-app-colaboras" v-if="enableColaborasSettings">
      <a @click="showColaborasSettingsFormModal" v-bind:title="colaborasSettingsBtnText" target="_blank">
        <div class="colaboras-footer-block">
          <img class="side-menu-app-icon" v-bind:src="settingsIcon" style="-webkit-filter:invert(100%);filter:invert(100%);"/>
        </div>
        <div class="colaboras-footer-block">
          <span class="side-menu-app-text">
            <span v-text="colaborasSettingsBtnText" class="colaboras-footer-text"></span>
          </span>
        </div>
      </a>
      <SideMenuColaborasSettingsForm 
        v-if="isColaborasSettingsFormModalOpen" 
        @close="hideColaborasSettingsFormModal"
      />
    </li>
    -->
    <!-- ** -->

    <!-- Formulario petición soporte - Informar de un error -->
     <!--
    <li class="side-menu-app side-menu-app-colaboras">
      <a @click="showColaborasSupportFormModal" v-bind:title="colaborasSupportBtnText" target="_blank">
        <div class="colaboras-footer-block">
          <img class="side-menu-app-icon" v-bind:src="infoIcon"/>
        </div>
        <div class="colaboras-footer-block">
          <span class="side-menu-app-text">
            <span v-text="colaborasSupportBtnText" class="colaboras-footer-text"></span>
          </span>
        </div>
      </a>
      <SideMenuColaborasSupportForm 
        v-if="isColaborasSupportFormModalOpen" 
        @close="hideColaborasSupportFormModal"
      />
    </li>
  -->
    <!-- ** -->

    <!-- Formulario envio de sugerencias -->
     <!--
    <li class="side-menu-app-colaboras side-menu-app">
      <a @click="showColaborasSuggestionsFormModal" v-bind:title="colaborasSuggestionsBtnText" target="_blank">
        <div class="colaboras-footer-block">
          <img class="side-menu-app-icon" v-bind:src="commentsIcon"/>
        </div>
        <div class="colaboras-footer-block">
          <span class="side-menu-app-text">
            <span v-text="colaborasSuggestionsBtnText" class="colaboras-footer-text"></span>
          </span>
        </div>
      </a>
      <SideMenuColaborasSuggestionsForm 
        v-if="isColaborasSuggestionsFormModalOpen" 
        @close="hideColaborasSuggestionsFormModal"
      />
    </li>
  -->
    <!-- ** -->

    <!-- Logo de Colaboras -->
    <li v-if="logoLink" class="side-menu-app-colaboras side-menu-logo-colaboras">
      <a v-bind:href="logoLink" target="_blank">
        <div class="colaboras-footer-block">
          <span class="side-menu-app-text">
            <img v-bind:src="colaborasLogo" class="colaboras-footer-logo" alt="Logo">
          </span>
        </div>
      </a>
    </li>
    <li v-else class="side-menu-app-colaboras side-menu-logo-colaboras">
      <div class="not-a-link">
        <div class="colaboras-footer-block">
          <span class="side-menu-app-text">
            <img v-bind:src="colaborasLogo" class="colaboras-footer-logo" alt="Logo">
          </span>
        </div>
      </div>
    </li>
    <!-- ** -->

  </ul>
</template>

<script>
import { imagePath } from '@nextcloud/router' //Incluir linea en package.json: "@nextcloud/router": "^2.0.1"
//import SideMenuColaborasSettingsForm from './SideMenuColaborasSettingsForm'
//import SideMenuColaborasSupportForm from './SideMenuColaborasSupportForm'
//import SideMenuColaborasSuggestionsForm from './SideMenuColaborasSuggestionsForm'
export default {
  name: 'SideMenuColaborasFooter',
  components: {
    //SideMenuColaborasSettingsForm,
    //SideMenuColaborasSupportForm,
    //SideMenuColaborasSuggestionsForm,
  },
  data() {
    return {
      //showColaborasSettingsMenuItem: false,
      //isColaborasSettingsFormModalOpen: false,
      //isColaborasSupportFormModalOpen: false,
      //isColaborasSuggestionsFormModalOpen: false,
      colaborasLogo: imagePath('side_menu', 'colaboras-logo.png'),
      //colaborasSettingsBtnText: t('side_menu', "Colaboras settings"),
      //colaborasSupportBtnText: t('side_menu', "Report a bug"),
      //colaborasSuggestionsBtnText: t('side_menu', "Suggestions"),
      //settingsIcon: imagePath('core', 'actions/settings'),
      infoIcon: imagePath('core', 'actions/info-white'),
      commentsIcon: imagePath('comments', 'comments'),
    }
  },
  props: {
    classes: {
      type: Object,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    logoLink: {
      type: String,
      required: true
    },
    isColaborasFooterLoading: {
      type: Boolean,
      required: true
    },
    /*enableColaborasSettings: {
      type: Boolean,
      required: true
    },*/
  },
  methods: {
    /*showColaborasSettingsFormModal() {
      this.isColaborasSettingsFormModalOpen = true
    },
    hideColaborasSettingsFormModal() {
      this.isColaborasSettingsFormModalOpen = false
    },*/
    /*showColaborasSupportFormModal() {
      this.isColaborasSupportFormModalOpen = true
    },
    hideColaborasSupportFormModal() {
      this.isColaborasSupportFormModalOpen = false
    },
    showColaborasSuggestionsFormModal() {
      this.isColaborasSuggestionsFormModalOpen = true
    },
    hideColaborasSuggestionsFormModal() {
      this.isColaborasSuggestionsFormModalOpen = false
    },*/
  }
}
</script>