<!--
@license GNU AGPL version 3 or any later version

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as
published by the Free Software Foundation, either version 3 of the
License, or (at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <http://www.gnu.org/licenses/>.
-->
<template>
    <div id="colaboras-floating-menu" class="colaboras-floating-menu">

        <div id="colaboras-toast" v-if="showColaborasToast">
            <SideMenuColaborasToast 
                v-if="showColaborasSpreedToast"
                v-bind:toastSize="small"
                v-bind:toastTitle="colaborasSpreedToastTitleText"
                v-bind:toastMessage="colaborasSpreedToastContentText"
                @close="closeColaborasSpreedToast"/>
        </div>
        
        <div ref="draggableContainer" 
                id="colaboras-draggable-container" 
                class="colaboras-draggable-container">

            <div id="colaboras-draggable-header" class="colaboras-draggable-header" >
                <img class="reset" 
                    v-if="showResetMeButton"
                    v-bind:src="colaborasResetMeIcon"
                    @click="resetFloatingMenu"/>
                <img class="move" 
                    v-bind:src="colaborasMoveMeIcon"
                    @mousedown="dragFloatingMenu"/>
            </div>

            <div id="colaboras-draggable-content" class="colaboras-draggable-content">

                <ul class="side-menu-floating-menu">
    
                    <!-- Formulario petición soporte - Informar de un error -->
                    <li class="colaboras-floating-item">
                        <a @click="showColaborasSupportFormModal" v-bind:title="colaborasSupportBtnText" target="_blank">
                            <div class="colaboras-floating-iconblock">
                                <div class="colaboras-floating-block">
                                    <span class="side-menu-app-text">
                                        <span v-text="colaborasSupportBtnText" class="colaboras-floating-text"></span>
                                    </span>
                                </div>
                                <img class="side-menu-app-icon colaboras-floating-icon" v-bind:src="infoIcon"/>
                            </div>
                        </a>
                        <SideMenuColaborasSupportForm 
                            v-if="isColaborasSupportFormModalOpen" 
                            @close="hideColaborasSupportFormModal"
                        />
                    </li>
                    <!-- ** -->

                    <!-- Formulario envio de sugerencias -->
                    <li class="colaboras-floating-item">
                        <a @click="showColaborasSuggestionsFormModal" v-bind:title="colaborasSuggestionsBtnText" target="_blank">
                            <div class="colaboras-floating-iconblock">
                                <div class="colaboras-floating-block">
                                    <span class="side-menu-app-text">
                                        <span v-text="colaborasSuggestionsBtnText" class="colaboras-floating-text"></span>
                                    </span>
                                </div>
                                <img class="side-menu-app-icon colaboras-floating-icon" v-bind:src="commentsIcon"/>
                            </div>
                        </a>
                        <SideMenuColaborasSuggestionsForm 
                            v-if="isColaborasSuggestionsFormModalOpen" 
                            @close="hideColaborasSuggestionsFormModal"
                        />
                    </li>
                    <!-- ** -->

                </ul>
                
            </div>
        </div>
        
    </div>
</template>

<script>
    import { imagePath } from '@nextcloud/router' //Incluir linea en package.json: "@nextcloud/router": "^2.0.1"
    import SideMenuColaborasSupportForm from './SideMenuColaborasSupportForm'
    import SideMenuColaborasSuggestionsForm from './SideMenuColaborasSuggestionsForm'
    import axios from '@nextcloud/axios'
    import SideMenuColaborasToast from './SideMenuColaborasToast'
    export default {
        name: 'SideMenuColaborasFloatingMenu',
        components: {
            SideMenuColaborasSupportForm,
            SideMenuColaborasSuggestionsForm,
            SideMenuColaborasToast,
        },
        data() {
            return {
                isColaborasSupportFormModalOpen: false,
                isColaborasSuggestionsFormModalOpen: false,
                colaborasSupportBtnText: t('side_menu', "Report a bug"),
                colaborasSuggestionsBtnText: t('side_menu', "Suggestions"),
                colaborasSpreedToastTitleText: t('side_menu', "Disconnection notice"),
                colaborasSpreedToastContentText: t('side_menu', "You've been disconnected from your current session in order to join as guest into a conversation hosted under a different domain"),
                colaborasMoveMeIcon: imagePath('side_menu', 'colaboras-move'),
                colaborasResetMeIcon: imagePath('core', 'actions/change'),
                showResetMeButton: false,
                infoIcon: imagePath('core', 'actions/info-white'),
                commentsIcon: imagePath('comments', 'comments'),
                positions: {
                    clientX: undefined,
                    clientY: undefined,
                    movementX: 0,
                    movementY: 0
                },
                showColaborasToast: false,
                showColaborasSpreedToast: false,
            }
        },
        props: {
            classes: {
                type: Object,
                required: true
            },
            /*logo: {
                type: String,
                required: true
            },
            logoLink: {
                type: String,
                required: true
            },*/
            isColaborasFooterLoading: {
                type: Boolean,
                required: true
            },
            /*enableColaborasSettings: {
                type: Boolean,
                required: true
            },*/
        },
        methods: {
            /*showColaborasSettingsFormModal() {
                this.isColaborasSettingsFormModalOpen = true
            },
            hideColaborasSettingsFormModal() {
                this.isColaborasSettingsFormModalOpen = false
            },*/
            showColaborasSupportFormModal() {
                this.isColaborasSupportFormModalOpen = true
            },
            hideColaborasSupportFormModal() {
                this.isColaborasSupportFormModalOpen = false
            },
            showColaborasSuggestionsFormModal() {
                this.isColaborasSuggestionsFormModalOpen = true
            },
            hideColaborasSuggestionsFormModal() {
                this.isColaborasSuggestionsFormModalOpen = false
            },
            resetFloatingMenu: function(event) {
                this.$refs.draggableContainer.style.top = '';
                this.$refs.draggableContainer.style.left = '';
                this.showResetMeButton = false
            },
            dragFloatingMenu: function(event) {
                event.preventDefault()
                // get the mouse cursor position at startup:
                this.positions.clientX = event.clientX
                this.positions.clientY = event.clientY
                document.onmousemove = this.elementDrag
                document.onmouseup = this.closeDragElement
            },
            elementDrag: function(event) {
                event.preventDefault()
                this.positions.movementX = this.positions.clientX - event.clientX
                this.positions.movementY = this.positions.clientY - event.clientY
                this.positions.clientX = event.clientX
                this.positions.clientY = event.clientY
                // set the element's new position:
                this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
                this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
                this.showResetMeButton = true
            },
            closeDragElement() {
                document.onmouseup = null
                document.onmousemove = null
            },
            checkIsColaborasSpreedToastVisible() {
                try {
                    axios
                    .post(OC.generateUrl('/apps/side_menu/colaboras/checkstatus'), {
                        action: "alert",
                        key: "logoutOnSpreedConversationDomainSwitch",
                    })
                    .then((response) => {
                        var responseData = response.data;
                        if (responseData.status === "success") {
                            if (responseData.data) {
                                this.showColaborasToast = true;
                                this.showColaborasSpreedToast = true;
                            }
                        }
                    })
                    .catch((error) => {
                        this.showColaborasToast = false;
                        this.showColaborasSpreedToast = false;
                    })
                } catch (error) {
                    this.showColaborasToast = false;
                    this.showColaborasSpreedToast = false;
                }
            },
            closeColaborasSpreedToast() {
                try {
                    axios
                    .post(OC.generateUrl('/apps/side_menu/colaboras/updatestatus'), {
                        action: "alert",
                        key: "logoutOnSpreedConversationDomainSwitch",
                        value: false,
                    })
                    .then((response) => {})
                    .catch((error) => {})
                } catch (error) {}
            },
        },
        mounted() {
            this.checkIsColaborasSpreedToastVisible();
        }
    }
</script>