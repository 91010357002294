import { render, staticRenderFns } from "./SideMenuColaborasSupportForm.vue?vue&type=template&id=71d7c21e&scoped=true"
import script from "./SideMenuColaborasSupportForm.vue?vue&type=script&lang=js"
export * from "./SideMenuColaborasSupportForm.vue?vue&type=script&lang=js"
import style0 from "./SideMenuColaborasSupportForm.vue?vue&type=style&index=0&id=71d7c21e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d7c21e",
  null
  
)

export default component.exports